<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <h5 class="py-3">購物指南已更新</h5>
        <b-form @submit.prevent="submitForm">
          <vue-editor
            id="termsAndConditions"
            :editorToolbar="descriptionToolbar"
            name="termsAndConditions"
            v-model="termsAndConditions"
          ></vue-editor>
          <b-button type="submit" class="mt-4" variant="primary">保存</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import commonService from '@/services/common.service'
export default {
  data () {
    return {
      descriptionToolbar: [
        ['bold', 'italic', 'underline', 'blockquote'],
        [
          { indent: '-1' },
          { indent: '+1' }
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { list: 'check' }
        ],
        ['link', 'image']
      ],
      termsAndConditions: ''
    }
  },
  components: {
    VueEditor
  },
  mounted () {
    this.getTermsAndConditions()
  },
  methods: {
    async getTermsAndConditions () {
      this.termsAndConditions = JSON.parse(await commonService.getTermsAndConditions())
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    submitForm () {
      console.log(this.termsAndConditions)
      commonService.addTermsAndConditions(this.termsAndConditions).then(
        (res) => {
          if (res.success) {
            this.getTermsAndConditions()
            this.makeToast(
              'success',
              '購物指南',
              '購物指南已更新'
            )
          }
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    }
  }
}
</script>
