<template>
  <b-container fluid>
    <b-row>
      <b-col cols="6">
        <h5>運費設定</h5>
        <b-alert show variant="danger" v-if="veeErrors.all().length">
          <ul class="list-unstyled mb-0">
            <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">
              {{ error }}
            </li>
          </ul>
        </b-alert>
        <b-form @submit.prevent="submitForm">
          <b-form-group
            id="deliveryFeeGroup"
            label="基本運費:"
            label-for="baseDeliveryFee"
            description="設定所有訂單的基本送貨費用"
          >
            <b-form-input
             @keydown="numberInputOnly"
              id="baseDeliveryFee"
              type="text"
              v-model="deliveryFee"
              placeholder="輸入基本運費"
              data-vv-as="baseDeliveryFee"
              aria-describedby="input-baseDeliveryFee-live-feedback"
              v-validate="'required|min_value:0'"
              :state="validateState('baseDeliveryFee')"
            >
            </b-form-input>
            <b-form-invalid-feedback id="input-baseDeliveryFee-live-feedback">{{
              veeErrors.first("baseDeliveryFee")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="thresholdGroup"
            label="免費送貨門檻:"
            label-for="thresholdGroup"
            description="為所有訂單設定免費送貨閾值"
          >
            <b-form-input
            @keydown="numberInputOnly"
              id="threshold"
              v-model="threshold"
              placeholder="輸入免運費閾值"
              data-vv-as="threshold"
              aria-describedby="input-threshold-live-feedback"
              v-validate="'required|min_value:0'"
              :state="validateState('threshold')"
            >
            </b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">保存</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import orderService from '../../services/order.service'
export default {
  data () {
    return {
      deliveryFeeDb: 0.0,
      thresholdDb: 0.0,
      deliveryFee: 0.0,
      threshold: 0.0
    }
  },
  mounted () {
    this.getDeliveryFee()
    this.getFreeDeliveryThreshold()
  },
  computed: {
    hasVeeErrors: ({ veeErrors }) => veeErrors.all().length === 0
  },
  methods: {
    async getDeliveryFee () {
      this.deliveryFeeDb = await orderService.getDeliveryFee()
      this.deliveryFee = this.deliveryFeeDb
    },
    async getFreeDeliveryThreshold () {
      this.thresholdDb = await orderService.getFreeDeliveryThreshold()
      this.threshold = this.thresholdDb
    },
    numberInputOnly (e) {
      // List of allowed keys: number keys, arrow keys, backspace, and decimal points
      const allowedKeys = [
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
        'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
        'Backspace', 'Delete', '.', 'Decimal'
      ]

      if (!allowedKeys.includes(e.key)) {
        e.preventDefault()
        return false
      }
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    filterVeeErrors (veeErrors = []) {
      return veeErrors.filter((element, index) => {
        return veeErrors.indexOf(element) === index
      })
    },
    submitForm () {
      this.$validator.validateAll().then(async (result) => {
        if (!result) {
          return
        }
        const formData = new FormData()
        formData.append('deliveryFee', this.deliveryFee)
        if (this.deliveryFee !== this.deliveryFeeDb) {
          orderService.setDeliveryFee(formData).then(
            (res) => {
              if (res.data.success) {
                this.getDeliveryFee()
                this.makeToast(
                  'success',
                  '快遞費',
                  '送貨費用已更改'
                )
              }
            },
            (err) => {
              this.makeToast('danger', '發生錯誤，請稍後再試。', err)
            }
          )
        }
        if (this.threshold !== this.thresholdDb) {
          orderService.setFreeDeliveryThreshold(this.threshold).then(
            (res) => {
              if (res.success) {
                this.getDeliveryFee()
                this.makeToast(
                  'success',
                  '快遞費',
                  '免運費門檻已更改'
                )
              }
            },
            (err) => {
              this.makeToast('danger', '發生錯誤，請稍後再試。', err)
            }
          )
        }
      })
    }
  }
}
</script>
