<template>
  <b-container fluid>
    <b-row>
      <b-col cols="6">
        <h5>WhatsApp URL</h5>
        <b-form @submit.prevent="submitForm">
          <b-form-group
            id="linkGroup"
            label="URL:"
            label-for="link"
            description=""
          >
            <b-form-input
              id="link"
              type="text"
              v-model="link"
              placeholder="Enter base delivery fee"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">保存</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import commonService from '@/services/common.service'
export default {
  data () {
    return {
      link: ''
    }
  },
  mounted () {
    this.getWhatsappUrl()
  },
  methods: {
    async getWhatsappUrl () {
      this.link = await commonService.getWhatsappUrl()
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    submitForm () {
      const formData = new FormData()
      formData.append('url', this.link)
      commonService.addWhatsappUrl(formData).then(
        (res) => {
          if (res.success) {
            this.getWhatsappUrl()
            this.makeToast(
              'success',
              'WhatsApp URL',
              'WhatsApp 網址已更改'
            )
          }
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    }
  }
}
</script>
