import http from './http-common'

class CommonService {
  async getTermsAndConditions () {
    return (await http.get('/staff/terms-and-conditions')).data.data
  }

  async addTermsAndConditions (data) {
    return (await http({
      method: 'POST',
      url: '/staff/terms-and-conditions/add',
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    })).data
  }

  async getWhatsappUrl () {
    return (await http.get('/staff/whatsapp-url')).data.data
  }

  async addWhatsappUrl (termsHtml) {
    const data = {
      termsAndConditions: termsHtml
    }
    return (
      await http({
        method: 'POST',
        url: '/staff/whatsapp-url/add',
        data: data,
        headers: {
          'Content-Type':
            'multipart/form-data;boundary = ' + new Date().getTime()
        }
      })
    ).data
  }
}

export default new CommonService()
